import { useState } from 'react';
import './style.scss';

const ModalWindow = ({ modalOpen, closeModal }) => {

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")

    const sendMessageToBot = async (botToken, chatId, text) => {
        try {
            const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;
            const params = {
                chat_id: chatId,
                text: text
            };

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            });

            const responseData = await response.json();


            if (response.status === 200) {
                alert("Дякую за звернення. Найближчим часом з вами зв'яжеться менеджер.")
                closeModal();

            } else {
                alert("Щось пішло не так. Можете спробувать пізніше або позвоніть за номером в шапці сайту")
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    }

    const onSendOrder = async () => {
        var message = `❓ЗВЕРНЕННЯ НА КОНСУЛЬТАЦІЮ:

    Ім'я: ${name}
    Телефон: ${phone};`;

        var token = "7454652534:AAGDxNAAa7yY37Uo3fC7rFvPeyk0ehuCR9w";
        var chat_id = "-1002161796663";

        await sendMessageToBot(token, chat_id, message)
    }

    return (
        <>
            <div className={`modal-container ${modalOpen ? 'active' : ''}`} >
                {/* Modal content */}
                <div className="modal-content">
                    <h2>Заявка на консультацію</h2>
                    <p class='modal-paragraph'>Після відправки заявки з вами зв'яжуться найближчим часом або в робочі години</p>
                    <input type="text" id="name" name="name" value={name} onChange={e => setName(e.target.value)} required placeholder="Ім'я"></input>
                    <input type="tel" id="phone" name="phone" value={phone} onChange={e => setPhone(e.target.value)} required placeholder="Номер телефона"></input>
                    <button className='send-button' onClick={onSendOrder}>Відправити</button>
                    <button className='close-button' onClick={closeModal}>X</button>
                </div>
            </div>
        </>

    )
}

export default ModalWindow;