import React, { useEffect, useState } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import FilterBlock from './components/FilterBlock/filter-block';
import ProductContainer from './components/ProductList/product-list';
import { useQuery } from '../../logic/hooks/useQuery';
import { CatalogData } from '../../data/catalog';
import IconButton from '../../components/Common/IconButton/button';
import { GalleryProducts } from '../../data/gallery';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const CatalogPage = ({ cartData, setCartData }) => {
    const [visibleFilterBlock, setVisibleFilterBlock] = useState(false);

    const query = useQuery();
    const category = query.get('category')
    const subCategory = query.get('subCategory')
    const search = query.get('search')

    let productFromData = []
    let productGallery = []

    if (search) {
        productFromData = CatalogData.filter(x => x.code.toLowerCase().includes(search.toLowerCase()))
    } else {
        productFromData = CatalogData.filter(x => x.category == category)
        productFromData = subCategory == null ? productFromData : productFromData.filter(x => x.subCategory == subCategory)
        productGallery = subCategory == null ? [] : GalleryProducts.filter(x => x.subCategory == subCategory)
    }


    const [products, setSelectedProducts] = useState(productFromData);
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(999999)

    const onChangeMinPrice = (minPrice) => {
        setMinPrice(minPrice)
    }

    const onChangeMaxPrice = (maxPrice) => {
        setMaxPrice(maxPrice)
    }

    const filterPrice = () => {
        setSelectedProducts(productFromData.filter(x => x.price >= minPrice && x.price <= maxPrice))
    }

    const [filterType, setFilterType] = React.useState(0);

    const onChangeFilterType = (event) => {
        setFilterType(event.target.value);
    };

    const filterTypePrice = () => {
        if (filterType == 10) {
            var list = productFromData.sort((a, b) => {
                const priceA = isNaN(a.price[0]) ? Number.MAX_SAFE_INTEGER : parseFloat(a.price[0]);
                const priceB = isNaN(b.price[0]) ? Number.MAX_SAFE_INTEGER : parseFloat(b.price[0]);

                return priceA - priceB;
            })

            setSelectedProducts(list)
        } else if (filterType == 20) {
            var list = productFromData.sort((a, b) => {
                const priceA = isNaN(a.price[0]) ? -1 : parseFloat(a.price[0]);
                const priceB = isNaN(b.price[0]) ? -1 : parseFloat(b.price[0]);

                if (priceA === -1 && priceB === -1) return 0; // Both non-numeric
                if (priceA === -1) return 1; // 'уточнюйте' comes last
                if (priceB === -1) return -1; // 'уточнюйте' comes last

                return priceB - priceA;
            })

            setSelectedProducts(list)
        } else {
            return
        }
    }

    useEffect(() => {
        filterPrice()
    }, [minPrice, maxPrice])

    useEffect(() => {
        filterTypePrice()
    }, [filterType])

    useEffect(() => {
        setSelectedProducts(productFromData)
    }, [category, subCategory, search])



    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <CommonLayout background={'url(images/background3.jpg)'}>
                <div className="catalog-page-wrapper">
                    <div className="filter-top">
                        <div className="filter-icon-mobile" onClick={() => setVisibleFilterBlock(true)}>
                            <IconButton iconUrl={"icons/filter.svg"} width={'40px'} height={'40px'} alt='filter' />
                        </div>
                        <FormControl variant="standard" sx={{ m: 2, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-standard-label">Сортування за ціною</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={filterType}
                                onChange={onChangeFilterType}
                                label="Filter"
                            >
                                <MenuItem value={10}>Від дешевих до дорогих</MenuItem>
                                <MenuItem value={20}>Від дорогих до дешевих</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="catalog-page-container">
                        <FilterBlock visible={visibleFilterBlock} setVisible={setVisibleFilterBlock} onChangeMinPrice={onChangeMinPrice} onChangeMaxPrice={onChangeMaxPrice}></FilterBlock>
                        <ProductContainer cartData={cartData} galleryImages={productGallery} setCartData={setCartData} products={products}></ProductContainer>

                    </div>


                </div>
            </CommonLayout>
        </>
    )
}

export default CatalogPage;