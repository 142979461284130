//Category
export const Monuments = "monuments";
export const Spotproducts = "spotproducts";
export const GraniteAccessories = "graniteAccessories";
export const Fencing = "fencing";
export const HomeDecor = "homeDecor";
export const BuildingMaterials = "buildingMaterials";
export const MarbleProducts = "marbleProducts";
export const Info = "info";

//SubCategory Monuments
export const Single = "single";
export const Double = "double";
export const Military = "military";
export const Family = "family";
export const Childrens = "childrens";
export const ForAnimals = "forAnimals";
export const European = "european";
export const Exclusive = "exclusive";
export const Addition = "addition";

export const MonumentsForFilterBlock = [
    [Single, "Одинарні", "/images/одинарні-підкатегорія.webp"],
    [Double, "Подвійні", "/images/подвійні-підкатегорія.webp"],
    [Military, "Військові", "/images/військові-підкатегорія.webp"],
    [Family, "Сімейні", "/images/sm1.webp"],
    [Childrens, "Дитячі", "/images/dt 1.webp"],
    [ForAnimals, "Для тварин", "/images/tv 9.webp"],
    [Exclusive, "Ексклюзивні", "/images/ексклюзивні-підкатегорія.webp"],
    [Addition, "Доповнення до пам'ятників", "/images/od (1).webp"]
];

//SubCategory Spotproducts
export const Vases = "vases";
export const Balls = "balls";
export const Spheres = "spheres";
export const Railings = "railings";
export const Balusters = "balusters";
export const Lampad = "Lampad";

export const SpotproductsForFilterBlock = [
    [Vases, "Вази", "/images/вази-підкатегорія.webp"],
    [Balls, "Шари", "/images/шари-підкатегорія.webp"],
    [Spheres, "Сфери", "/images/полусфера-підкатегорія.webp"],
    [Railings, "Перила", "/images/перила-підкатегорія.webp"],
    [Balusters, "Балясини", "/images/балясини-підкатегорія.webp"],
    [Lampad, "Лампадки", "/images/лампадки-підкатегорія.webp"]
];

//SubCategory GraniteAccessories
export const Ashtray = "ashtray";
export const NapkinHolder = "napkin-holder";
export const CuttingBoards = "cutting-boards";
export const CandyBoxes = "candy-boxes";
export const SoccerBalls = "soccer-balls";
export const Urns = "urns";
export const Crosses = "crosses";

export const GraniteAccessoriesForFilterBlock = [
    [Ashtray, "Попільнички", "/images/попіл-підкатегорія.webp"],
    [NapkinHolder, "Сарветниці", "/images/сарв-підкатегорія.webp"],
    [CuttingBoards, "Доски для нарізання", "/images/дошки-підкатегорія.webp"],
    [CandyBoxes, "Цукерниці", "/images/цукерниці-підкатегорія.webp"],
    [SoccerBalls, "Футбольні м'ячи", "/images/мяч-підкатегорія.webp"],
    [Urns, "Урни для праху", "/images/урна-підкатегорія.webp"],
    [Crosses, "Гранітні хрести", ""],
];

//SubCategory Fencing
export const Fenc = "fenc";
export const Gabions = "gabions";
export const ForMonuments = "forMonuments";

export const FencingForFilterBlock = [
    [Fenc, "Забори", "/images/забори-підкатегорія.webp"],
    [Gabions, "Габіони", "/images/габіони-підкатегорія.webp"]
];

//SubCategory HomeDecor
export const Fountains = "fountains";
export const Boulders = "boulders";
export const SinksBathtubs = "sinks-bathtubs";
export const Countertops = "countertops";
export const Stairs = "stairs";
export const WindowSills = "window-sills";

export const HomeDecorForFilterBlock = [
    [Fountains, "Фонтани", "images/фонтани-підкатегорія.webp"],
    [Boulders, "Валуни", "images/валуни-підкатегорія.webp"],
    [SinksBathtubs, "Раковини та ванни", "/images/вана-підкатегорія.webp"],
    [Countertops, "Стільниці", "/images/стільниці-підкатегорія.webp"],
    [Stairs, "Сходи", "/images/сходи-підкатегорія.webp"],
    [WindowSills, "Підвіконня", "/images/підвіконня-підкатегорія.webp"]
];

//SubCategory MarbleProducts
export const Sculptures = "sculptures";

export const MarbleProductsForFilterBlock = [
    [Sculptures, "Скульптури"]
];

//SubCategory BuildingMaterials
export const Cobblestones = "cobblestones";
export const Curbs = "curbs";
export const FacadeTiles = "facade-tiles";
export const PavingTiles  = "paving-tiles";
export const Slabs = "slabs";
export const CrushedStone = "crushed-stone";

export const BuildingMaterialsForFilterBlock = [
    [Cobblestones, "Бруківка", "/images/бруківка-підкатегорія.webp"],
    [Curbs, "Бордюр", "/images/бордюри-підкатегорія.webp"],
    [FacadeTiles, "Плитка фасадна", "/images/пф-підкатегорія.webp"],
    [PavingTiles, "Плитка мощення", "/images/пм-підкатегорія.webp"],
    [Slabs, "Слеби", "/images/сляби-підкатегорія.webp"],
    [CrushedStone, "Бутовий камінь", "/images/бут.webp"]
];


